<template>
  <v-container class="pa-4">
    <Substipulator
      :contractId="$route.params.id"
      @sub="subSelected($event)"
      v-if="hasSub"
    />

    <v-form @submit.prevent="save" ref="form" lazy-validation v-model="valid">
      <v-row dense>
        <v-col cols="12" xl="12" lg="12" md="12" sm="12">
          <v-textarea
            id="annotation"
            name="annotation"
            label="Anotação"
            rows="10"
            outlined
            required
            :rules="[required]"
            v-model="model.annotation"
          />
        </v-col>
      </v-row>

      <v-divider class="my-5" />

      <v-row dense>
        <v-col cols="12" xl="12" lg="12" md="12" sm="12" class="text-right">
          <BaseButton
            id="btn-back"
            name="btn-back"
            title="Voltar"
            color="primary"
            outlined
            @click="back"
          />

          <template v-if="contract.situation !== 'Cancelado'">
            <BaseButton
              id="btn-save"
              name="btn-save"
              type="submit"
              color="primary"
              title="Salvar"
              height="40"
              :disabled="!valid"
              v-permission="{
                roles: ['A1', 'AC1', 'AC2', 'AC3', 'ACI1', 'ACI2', 'ACI3'],
                operator: '==='
              }"
            />
          </template>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import { rulesMixin } from '@/mixins/rules';
import { mapGetters } from 'vuex';
import { showMessage } from '@/helpers/messages';
import { ContractService } from '@/services/api/contracts';

export default {
  mixins: [rulesMixin],

  components: {
    Substipulator: () => import('./commons/substipulator')
  },

  props: {
    productType: {
      type: String
    },
    customerId: {
      type: String
    },
    hasSub: {
      type: Boolean
    }
  },

  data: () => ({
    substipulator: null,
    valid: true,
    model: {
      id: null,
      annotation: null
    }
  }),

  created() {
    this.search();
  },

  computed: {
    ...mapGetters({
      contract: 'contracts/getContractById'
    })
  },

  methods: {
    async search() {
      try {
        const contractService = new ContractService();
        const { status, data } = await contractService.getAnnotation({
          contractId: this.$route.params.id,
          customerId: this.substipulator ? this.substipulator : this.customerId
        });

        if (status === 200) {
          this.model = data;
        }
      } catch (error) {
        throw new Error(error);
      }
    },

    back() {
      this.$emit('back');
    },

    save() {
      if (!this.$refs.form.validate(true)) {
        return;
      }

      const params = {
        contractId: this.$route.params.id,
        customerId: this.substipulator ? this.substipulator : this.customerId,
        ...this.model
      };

      if (this.model.id) {
        this.update(params);
      } else {
        this.create(params);
      }
    },

    async create(params) {
      try {
        const contractService = new ContractService();
        const { status } = await contractService.createAnnotation(params);

        if (status === 204) {
          showMessage('success', 'Operação realizada com sucesso');
          this.search();
        }
      } catch (error) {
        throw new Error(error);
      }
    },

    async update(params) {
      try {
        const contractService = new ContractService();
        const { status } = await contractService.updateAnnotation(params);

        if (status === 204) {
          showMessage('success', 'Operação realizada com sucesso');
          this.search();
        }
      } catch (error) {
        throw new Error(error);
      }
    },

    subSelected(event) {
      this.substipulator = event;

      this.model = {
        id: null,
        annotation: null
      };

      this.$refs.form.reset();
      this.search();
    }
  }
};
</script>
